.MuiToolbar-regular{
  height: 110px;

}


.wrapper {

  text-align: center;
}

.titleWhite{
  font-size: 25px;
  font-weight: 900;
  color: white;
  text-decoration: none;
 
}




.titleBlack{
  font-size: 25px;
  font-weight: 900;
  color: black;
  display: inline-block;
  text-decoration: none;
  
}


.MuiGrid-grid-xs-2 {
  flex-grow: 0;
  max-width: 16.666667%;
  flex-basis: 16.666667%;
  text-align: -webkit-center !important;
}


li { 
  display: table-cell; 
  position: relative; 
  padding: 15px 0;
}
.navLink {
  color: #fff !important;
  text-transform: uppercase !important;
  text-decoration: none !important;
  letter-spacing: 0.15em !important;
  font-size: 12px !important;;
  
  display: inline-block !important;
  padding: 15px 20px !important;
  position: relative !important;
}

.navLink.black {
  color: #000 !important;
  text-transform: uppercase !important;
  text-decoration: none !important;
  letter-spacing: 0.15em !important;
  
  display: inline-block !important;
  padding: 15px 20px !important;
  position: relative !important;
}
.navLink:after {    
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.navLink.black:after {    
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #000;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.navLink:hover:after { 
  width: 100%; 
  left: 0; 
}


.titleItems.black{
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: black;
}



.navbar{
  background-color: transparent !important;
  box-shadow: 0px 0px 0px 0px !important;
  transition: 0.5s !important;
  height: 70px;
  margin-top: 10px;
  margin-right: 50px;
  width: calc(100% - 100px) !important;
}

.navbar.active{
  background-color: white !important;
  transition: 0.5s !important;
  border: 1px solid #cacaca;
  border-radius: 20px;
  
}


.background{
  background-color: black;
  height: 90vh;
}


.backgroundOne{
  background-color: black;
  height: 60vh;
}


.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 90vh;

  background-size: cover;
  background-position: center;
  will-change: opacity;
}

.bgOne{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 60vh;

  background-size: cover;
  background-position: center;
  will-change: opacity;
}


.withinImage{
  position: absolute;
  z-index: 1000;
  top: 40%;
  color: white;
  font-size: 35px;

  display: inline-block;

}




.textWithin {
  position: absolute;
  left: 50%;
  top: 40%;
  width: 400px;
  font-weight: 900;
  font-size: 35px;
  color: white;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 1000;
}

.fee{
 font-size: 1.3em;
}



.imageText2{

   width: 100%;
   margin-left: 4em;
   border-radius: 20px;
   box-shadow: 1px 1px 4px 0 rgba(0,0,10,0.04), 0 40px 100px -20px rgba(0,0,10,0.2), 0 30px 70px -30px rgba(0,0,0,0.4);


}

.imageText3{
  object-fit: contain;
  width: 300px;
  height: 300px;
  /*object-position: 50% 25%;  move the image down by 20% from the top */
  border-radius: 20px;
  box-shadow: 1px 1px 4px 0 rgba(0,0,10,0.04), 0 40px 100px -20px rgba(0,0,10,0.2), 0 30px 70px -30px rgba(0,0,0,0.4);
}

.textWithinSmall {
  position: absolute;
  left: 50%;
  top: 35%;
  width: 600px;
  font-weight: 100;
  font-size: 18px;
  color: white;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 1000;
}

.progHighlight{
margin-top: -3em !important;
}

.spacing{
   margin-top: 9em !important;
}


.titleText{
  font-size: 2rem;
  line-height: 1.1em;
  font-weight: 700;
  text-align: left;
  margin-top: 50px;
  margin-bottom: 1.5rem;
}

.logoIcon {
 
  height: 80px;
}

.navtitle {
  font-weight: 400;
  font-size: 23px!important;
  color: #fff!important;
}

.navbar-brand {
  display: inline-block;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.name {

  display: inline-block;
  padding-top: 20px;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  vertical-align: 140%;
  padding-left: 1rem;
  position: relative;
  top: 7px;

 
}

.iamrobot {

  color: black;
  font-weight: 1000;
  
 

}

.footlogo {
  width: 200px;
  height: 200px;
  position: absolute;
  margin-left: -280px;
  display: inline-block!important;
  vertical-align: top!important;
}

.text1 {
text-align: left;
}



.subtitleText{
  color: #505050;
  font-size: 15px;
  line-height: 1.5em;
  text-align: left;
  
}

.subtitleText2{
  color: #505050;
  font-size: 14px;
  line-height: 1.5em;
  text-align: left;
  
}



.imageText{
   width: 100%;
   height: 450px;
   object-fit: cover;
   border-radius: 20px;
   box-shadow: 1px 1px 4px 0 rgba(0,0,10,0.04), 0 40px 100px -20px rgba(0,0,10,0.2), 0 30px 70px -30px rgba(0,0,0,0.4);
}

.buttonIntro{
  width: 29%;
  padding: 16px 0px !important;
  float: left;
  font-weight: 800 !important;
  border-radius: 30px !important;
  
  font-size: 14px !important;
  margin-top: 3rem !important;
}

.MuiButton-containedPrimary{
  background-color: rgb(64 123 255) !important;
}

.MuiBottomNavigationAction-label.Mui-selected{
  margin-top: 10px !important;
}


.videoText{
  width: 100%;
  height: 450px !important;
  object-fit: cover;
  border-radius: 20px;
  box-shadow: 1px 1px 4px 0 rgba(0,0,10,0.04), 0 40px 100px -20px rgba(0,0,10,0.2), 0 30px 70px -30px rgba(0,0,0,0.4);
}

p {
  text-align: center;
  font-weight: 500;
}
input[type=text],input[type=email], input[type=tel], input[type=number], select, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}
input[type=submit] {
  background-color: #4CAF50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
input[type=submit]:hover {
  background-color: #45a049;
}


.MuiBottomNavigationAction-root.Mui-selected{
  border-radius: 10px !important;
}

/* Forms Section Start */
.formHere{
  padding-top: 50px;
  width: 70%;
  margin: 0 auto;
}


.formItem{
  text-align: left;
  padding-left: 20px !important;
}

.makeStyles-root-1{
  width: auto !important;
}


.labelCheck{
  font-size: 14px;
  color: gray;
  margin: 3px;
}

.system1{
  width: 100%;
}

.paper1{
  width: 50%;
}


/* Forms Section End */



@media screen and (max-width: 776px){

  .paper1{
    width: 70%;
  }
  .navbar{
    width: 100% !important;
    margin-right: 0px !important;
  }
  .titleWhite{
    font-size: 20px;
  }

  .textWithinSmall{
    width: 85%;
    line-height: 25px;
    text-align: left;
    top: 56%;
  }

  .textWithin{
    width: 85%;
    text-align: left;
    top: 45%;
    font-size: 28px;
  }


  .videoText{
    height: 250px !important;
  }

  .titleText{
    margin-top: 40px;;
    font-size: 2rem;
  }
  .buttonIntro{
    width: 100%;
    margin-top: 2rem !important;
  }
  .MuiGrid-spacing-xs-6{
    width: 100% !important;
    padding-top: 3em !important;
    margin: 0 !important;
  
  }
 
  .MuiGrid-spacing-xs-6 > .MuiGrid-item{
    padding: 0px !important;
  }

  .container {
    flex-direction:column
  }
  .first{
    order: 3;
  
    
  }

  .spacing{
    margin-top: 3rem !important;
  }
  .spacing:first-child{
    margin-top: 2rem !important;
  }
  .spacing:last-child{
    margin-bottom: 7rem !important;
  }
  .second{
    order: 2;

  }

  .extra{
    padding-top: 100px !important;
  }

  .subtitleText{
    font-size: 12px;
  }

  .imageText{
    height: 250px;

  }

  .formHere{
    width: 100%;
  }
  .formItem {
    text-align: left;
    padding-right: 8px;
    padding-left: 8px !important;
  }

  .progHighlight{
    margin-top: 1rem !important;
  }

  .imageText2{
    display: none;
  }
}